* {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Roboto Serif', serif;
}

a {
  text-decoration: none;
  outline: none;
}

a:hover {
  outline: none;
}
a:focus {
  outline: none;
}
